import { BackupPage } from "@vt-team/design-system-deprecated"
import { withEmergy } from "../../utils/withEmergy"
import { sidePanelDefinition } from "../../utils/sidePanelDefinition"

export default withEmergy(BackupPage.Page, {
  logoUrl: "https://cdn.vt.team/f/txs.vt.team/logo-transparent.web.png",
  sidePanelDefinition: sidePanelDefinition,
  userPoolId: typeof window !== "undefined" && window['siteConfig']?.userPoolId,
  clientId: typeof window !== "undefined" && window['siteConfig']?.clientId
})
